import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchForms(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/forms`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchForm(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/form/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateForm(ctx, { id, formData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_HOST}/api/dashboard/form/${id}/update`, formData)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteForm(ctx, { id, auth }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/form/${id}/delete?auth=${auth}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}