<template>

    <div>
         <b-card>
			<b-row>					
				<b-col cols="12" class="mb-1">
					<h5>FILTROS</h5>
				</b-col>
				<b-col sm="12" md="4">
					<label>Revisor(es)</label>
					<v-select 
                        multiple
					    v-model="filterReviser"
					    :options="optionsRevisers" />
				</b-col>
				<b-col sm="12" md="4">
					<label>Estado(s) de formulario</label>
					<v-select 
                        multiple
					    v-model="filterState"
					    :options="optionsStates" />
				</b-col>
				<b-col sm="12" md="4">
					<label>Nivel(es)</label>
					<v-select 
                        multiple
					    v-model="filterLevel"
					    :options="optionsLevels" />
				</b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col sm="12" md="4">
                    <label>Fecha de inicio</label>
                     <flat-pickr
                        v-model="filterStartDate"
                        class="form-control"
                        :config="config"
                    />
                </b-col>
                <b-col sm="12" md="4">
                    <label>Puntaje {{idForm }}</label>                    
                    <b-form-input
                        placeholder="0.00"
                        v-model="filterScore"
                        type="number" 
                        step=0.01
                    />
                </b-col>
			</b-row>
		</b-card>
                
        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>                    

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Buscar ..."
                            />
                        </div>
                    </b-col>
                </b-row>

            </div>

            
            <b-table
                ref="refFormListTable"
                class="position-relative"
                :style="totalUsers < 3 && totalUsers > 0 ? 'height: 250px' : 'height: inherit'"
                :items="fetchForms"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc">
                
                <template #cell(certificate_date)="data">
                    <template v-if="data.item.certificate_date">  
                        <b-col style="width: 150px">
                            {{ moment(data.item.certificate_date).format('DD-MM-YYYY HH:mm') }}
                        </b-col>
                    </template>
                    <template v-else>
                        ---
                    </template>                  
                </template>
                
                <template #cell(start_date)="data">
                    <b-col style="width: 200px">
                        {{ moment(data.item.start_date).format('DD-MM-YYYY') }} 00:00:00
                    </b-col>                    
                </template>
                
                <template #cell(deadline)="data">
                    <template v-if="data.item.deadline">  
                        <b-col style="width: 200px">
                            {{ moment(data.item.deadline).format('DD-MM-YYYY') }} 23:59:59
                        </b-col>
                    </template>
                    <template v-else>
                        ---
                    </template>
                </template>
                
                <template #cell(date_dispatch_review)="data">
                    <template v-if="data.item.date_dispatch_review">  
                        <b-col style="width: 150px">
                            {{ moment(data.item.date_dispatch_review).format('DD-MM-YYYY') }}
                        </b-col>
                    </template>
                    <template v-else>
                        ---
                    </template>
                </template>
                
                <template #cell(date_finished_review)="data">                     
                    <template v-if="data.item.date_finished_review">  
                        <b-col style="width: 150px">
                            {{ moment(data.item.date_finished_review).format('DD-MM-YYYY') }}
                        </b-col>
                    </template>
                    <template v-else>
                        ---
                    </template>                 
                </template>
                
                <template #cell(reviser.access.fullName)="data"> 
                    <template v-if="data.item.reviser">  
                        <b-col>
                            {{ data.item.reviser.access.fullName }}
                        </b-col>
                    </template>
                    <template v-else>
                        ---
                    </template>                 
                </template>
                
                <template #cell(state.name)="data">                    
                    <template v-if="data.item.state">         
                        <b-badge :variant="`light-${data.item.state.color}`" >
                            {{data.item.state.name}}
                        </b-badge>
                    </template>
                    <template v-else>
                        ---
                    </template>                    
                </template>
                
                <template #cell(level.name)="data">
                    <template v-if="data.item.level">         
                        <b-badge :variant="`light-${data.item.level.color}`" >
                            {{data.item.level.name}}
                        </b-badge>
                    </template>
                    <template v-else>
                        ---
                    </template> 
                </template>
                
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">
                        
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        
                        <b-dropdown-item 
                            v-if="data.item.report_storage && data.item.state.name == 'COMPLETADO'" 
                            @click='fileSrc(data.item.report_storage)'
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Informe
                            </span>
                        </b-dropdown-item>        
                        
                        <b-dropdown-item 
                            v-if="data.item.memo_code && data.item.state.name == 'COMPLETADO' && data.item.memo_storage" 
                            @click='fileSrc(data.item.memo_storage)'
                        >   
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Memorándum
                            </span>
                        </b-dropdown-item>      
                        
                        <b-dropdown-item 
                            v-if="data.item.memo_tariff_storage && data.item.state.name == 'COMPLETADO'"
                            @click='fileSrc(data.item.memo_tariff_storage)'
                        >   
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Memorándum de Tarifario
                            </span>
                        </b-dropdown-item> 
                        
                        <b-dropdown-item 
                            v-if="data.item.report_daily_wages_storage && data.item.state.name == 'COMPLETADO'"
                            @click='fileSrc(data.item.report_daily_wages_storage)'
                        >   
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Reporte de Tarifario
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item 
                            v-if="data.item.report_labor_criticality_storage && data.item.state.name == 'COMPLETADO'" 
                            @click='fileSrc(data.item.report_labor_criticality_storage)'
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Criticidad Laboral
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item 
                            v-if="data.item.state.name == 'COMPLETADO'" 
                            @click='downloadSupportJobCriticality(data.item)'
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Sustentos Criticidad Laboral
                            </span>
                        </b-dropdown-item> 

                        <b-dropdown-item 
                            v-if="data.item.report_observations_storage && data.item.state.name == 'COMPLETADO'" 
                            @click='fileSrc(data.item.report_observations_storage)'
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Reporte de Observaciones
                            </span>
                        </b-dropdown-item>
                        
                        <b-dropdown-divider v-if="data.item.report_storage" />
                                                
                        <b-dropdown-item :to="{ name: 'forms-view', params: { id: data.item.id } }">
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">Ver</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="openEdit(data.item.id)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>
                        
                        <b-dropdown-item @click="deleteForm(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                        
                    </b-dropdown>                    
                </template> 

            </b-table>
        
            
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
            
            <template v-if="editData">
                <b-sidebar
                    id="edit-form-sidebar-id"        
                    ref="sidebar"
                    bg-variant="white"
                    sidebar-class="sidebar-lg"
                    shadow
                    backdrop
                    no-close-on-backdrop 
                    no-header
                    right
                    :visible="visibleSidebar"
                >                
                    <template>
                    
                        <!-- Header -->
                        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                            <h5 class="mb-0">
                                <strong>EDITAR FORMULARIO</strong>
                            </h5>            
                        </div>

                        <!-- BODY -->                                      
                        <b-form class="p-2">
                            <!-- Reviser -->                                
                            <b-form-group
                                label="Revisor"
                                label-for="reviser"
                            >
                                <v-select
                                    id="reviser-id"
                                    :options="optionsRevisers"
                                    v-model="editData.reviser_name"
                                />
                            </b-form-group>
                        
                            <!-- State -->                                
                            <b-form-group
                                label="Estado del formulario"
                                label-for="state"
                            >                                       
                                <v-select
                                    id="state-id"                                            
                                    :options="optionsStates"
                                    v-model="editData.state_name"
                                />                                
                            </b-form-group>
                            
                            <!-- Start Date -->                               
                            <b-form-group
                                label="Fecha de Inicio"
                                label-for="start_date"
                            >                            
                                <flat-pickr
                                    id="start_date"
                                    class="form-control"
                                    :config="{ minDate: minStartDate }"
                                    v-model="editData.start_date"
                                />    
                            </b-form-group>   
                            
                            <!-- Deadline -->                                                         
                                <b-form-group
                                    label="Fecha Límite"
                                    label-for="deadline"
                                >                     
                                    <flat-pickr
                                        id="deadline"
                                        class="form-control"
                                        :config="{minDate: minDeadline}"
                                        v-model="editData.deadline"                                                
                                    />                    
                                </b-form-group>
                            
                            
                            <!-- Form Actions -->
                            <div class="d-flex mt-2">
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    class="mr-2"
                                    @click="updateForm"
                                >
                                    ACTUALIZAR
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    type="button"
                                    variant="outline-secondary"
                                    @click="closeSidebar"
                                >
                                    CANCELAR
                                </b-button>
                            </div>
                            
                        </b-form>                            
                    </template>
                </b-sidebar>     
            </template>
                       
        </b-card>
    </div>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BAvatar, BDropdownDivider, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormGroup,BFormInput, BLink, BMedia, BPagination, BRow, BTable, BFormDatepicker, } from 'bootstrap-vue';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import vSelect from 'vue-select';
    import store from '@/store';
    import VueSweetalert2 from 'vue-sweetalert2';
    import { onUnmounted, ref, reactive } from '@vue/composition-api';
    import { avatarText } from '@core/utils/filter';
    import useFormsList from './useFormsList';
    import formStoreModule from '../formStoreModule';
    import FormEditTabInformation from '../forms-edit/FormEditTabInformation'
    import flatPickr from 'vue-flatpickr-component'
    import Ripple from 'vue-ripple-directive';
    import { v4 as uuidv4 } from 'uuid'
    import Vue from 'vue';
    import moment from 'moment'
    import axios from 'axios'
    import 'animate.css';    
    
    Vue.use(VueSweetalert2);

    export default {
        components: {
            FormEditTabInformation,
            
            BDropdownDivider,
            BCard,
            BRow,
            BCol,
            BFormInput,
            BFormGroup,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BFormDatepicker,
            vSelect,
            flatPickr,
        },
        directives: {
            Ripple,
        },
        data() {                              
            return {
                editData: {},
                minStartDate: "",
                minDeadline: "",
                visibleSidebar: false,
                moment,
                config: {
                    enableTime: false, 
                    dateFormat: 'd-m-Y',
                    locale: Spanish
                }
            }
        }, 
        methods: {
            fileSrc (file){
                window.open(`${process.env.VUE_APP_FILES}/${file}?str=${uuidv4()}`, "_blank");
            },
            async downloadSupportJobCriticality (item){
                const response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/form/${item.id}/support-job-criticality`);
                
                if (!response.data.count){
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });
                } else {
                    window.open(`${process.env.VUE_APP_FILES}/${response.data.file}?str=${uuidv4()}`, "_blank");
                }
            },
            openEdit(id){                
                this.visibleSidebar = true;     
                store.dispatch('app-form/fetchForm', { id })
                .then(response => {
                    if (response.data === 'error'){
                        this.editData = undefined;
                    } else {                       
                         this.editData = {};  
                         this.editData = {
                            id: response.data.id,
                            start_date: response.data.start_date,
                            deadline: response.data.deadline,
                            reviser_id: response.data.reviser ? response.data.reviser.id : null,
                            reviser_name: response.data.reviser ? response.data.reviser.access.fullName : null,
                            state_id: response.data.state ? response.data.state.id : null,
                            state_name: response.data.state ? response.data.state.name : null,                            
                        };                        
                        this.minStartDate = this.editData.start_date;
                        this.minDeadline = this.editData.deadline;   
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        this.editData = undefined;
                    }
                });
                
            },
            
            updateForm(){

                let reviserId = null;
                let stateId= null;
                
                if (this.editData.reviser_name){
                    reviserId = typeof  this.editData.reviser_name === "string" ? 
                                        this.editData.reviser_id :
                                        this.editData.reviser_name.value;
                }

                if (this.editData.state_name){
                    stateId = typeof  this.editData.state_name === "string" ? 
                                       this.editData.state_id :                            
                                       this.editData.state_name.value;  
                }
                
                let formData = {                    
                    reviser_id: reviserId,
                    state_id: stateId,
                    deadline: moment(this.editData.deadline).format("YYYY-MM-DD HH:mm"),
                    start_date: moment(this.editData.start_date).format('YYYY-MM-DD'),         
                    auth: JSON.parse(localStorage.getItem('userData')).id
                }
                
                store.dispatch('app-form/updateForm', { id: this.editData.id, formData })	
                .then( (response) => {        
                    
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    });                    
                    this.visibleSidebar = false;
                    this.$refs.refFormListTable.refresh();
                })
                .catch( (err) => {
                    let message = err.response.data.message ? err.response.data.message : 'Error al actualizar el formulario.';
                    
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });
                });
            },
            closeSidebar(){
                this.visibleSidebar = false;
            }
        },
        setup() {
            // Use toast
            const toast = useToast();
            
            const USER_APP_STORE_MODULE_NAME = 'app-form';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, formStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });
            
            const isEditFormSidebarActive = ref(false);        
            
            const optionsRevisers = reactive([]);            
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/revisers/select`)
            .then(response => {
                
                response.data.map( item => {
					optionsRevisers.push({
						label : item.access.fullName, 
						value : item.id
					});
				});
            });
            
            const optionsStates = reactive([]);            
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/state/select`)
            .then(response => {
                 response.data.map( item => {
					optionsStates.push({
						label : item.name, 
						value : item.id
					});
				});
            });
            
            const optionsLevels = reactive([]);            
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/level/select`)
            .then(response => {
                 response.data.map( item => {
					optionsLevels.push({
						label : item.name, 
						value : item.id
					});
				});
            });

            const editData = ref(null); 
            const idForm = ref(null);
            const sidebarActive = ref(false);
            
            const editForm = (id) => {
                isEditFormSidebarActive.value = true;
            };
            
            const {
                fetchForms,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refFormListTable,
                refetchData,
                
                filterReviser,
                filterLevel,
                filterState,
                filterStartDate,
                filterScore,
            } = useFormsList();

            const deleteForm = (id) => {
                Vue.swal({
                    title: '¿Estás seguro de eliminar el formulario?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        store.dispatch('app-form/deleteForm', { id, auth: JSON.parse(localStorage.getItem('userData')).id })
                            .then( (response) => {
                                refetchData();
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( () => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al eliminar el formulario',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {

                // Sidebar
                isEditFormSidebarActive,
                idForm,
                sidebarActive,
                editData,
                editForm,
                
                fetchForms,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refFormListTable,
                refetchData,       

                // Filter
                avatarText,
                deleteForm,
                
                filterReviser,
                filterLevel,
                filterState,
                filterStartDate,
                filterScore,
                optionsRevisers,
                optionsStates,
                optionsLevels,
            }
        }
    }

</script>

<style lang="scss" scoped>

    .media {
        align-items: center;
    }

    .per-page-selector {
        width: 90px;
    }    

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>