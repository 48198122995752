<template>
    <b-sidebar
        id="edit-form-sidebar"
        :visible="isEditFormSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-edit-form-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>EDITAR FORMULARIO</strong>
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <template v-if="formData != null">                
                    <b-form
                        class="p-2"
                        @submit.prevent="handleSubmit(onSubmit)"
                        @reset.prevent="resetForm"
                    >
                        <!-- Reviser -->
                        <validation-provider
                            #default="{ errors }"
                            name="revisor"
                            rules="required"
                        >
                            <b-form-group
                                label="Revisor"
                                label-for="reviser"
                            >
                                <v-select
                                    id="reviser"
                                    v-model="formData.reviser.access.fullName"
                                    :options="optionsRevisers"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                    
                        <!-- State -->
                        <validation-provider
                            #default="{ errors }"
                            name="estado"
                            rules="required"
                        >
                            <b-form-group
                                label="Estado del formulario"
                                label-for="state"
                            >
                                <v-select
                                    id="state"
                                    label="name"
                                    v-model="formData.state.name"
                                    :options="optionsStates"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                        
                         <!-- Start Date -->       
                        <validation-provider
                            #default="{ errors }"
                            name="fecha inicio"
                            rules="required"
                        >
                            <b-form-group
                                label="Fecha de Inicio"
                                label-for="start_date"
                            >                                
                                <flat-pickr
                                    id="start_date"
                                    class="form-control"
                                    :config="{ minDate: formData.start_date }"
                                    v-model="formData.start_date"
                                    :state="errors.length > 0 ? false:null"
                                />    
                                <small class="text-danger">{{ errors[0] }}</small>                    
                            </b-form-group>                        
                        </validation-provider>
                        
                        <!-- Deadline -->                         
                        <validation-provider
                            #default="{ errors }"
                            name="fecha limite"
                            rules="required"
                        >
                            <b-form-group
                                label="Fecha Límite"
                                label-for="deadline"
                            >                                
                                <flat-pickr
                                    id="deadline"
                                    class="form-control"
                                    :config="{ enableTime: true, dateFormat: 'd-m-Y H:i', minDate: formData.deadline }"
                                    v-model="formData.deadline"
                                    :state="errors.length > 0 ? false:null"
                                    locale="es"
                                />    
                                <small class="text-danger">{{ errors[0] }}</small>                    
                            </b-form-group>
                        
                        </validation-provider>
                        
                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                type="submit"
                            >
                                ACTUALIZAR
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                @click="hide"
                            >
                                CANCELAR
                            </b-button>
                        </div>

                    </b-form>
                </template>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormFile, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupAppend, BCol, BRow,
    BFormDatepicker } from 'bootstrap-vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { onUnmounted, ref, reactive } from '@vue/composition-api';
    import { required, alphaNum } from '@validations';
    import formValidation from '@core/comp-functions/forms/form-validation';
    import Ripple from 'vue-ripple-directive';
    import vSelect from 'vue-select';
    import store from '@/store';
    import flatPickr from 'vue-flatpickr-component'
    import formStoreModule from '../formStoreModule';
    import axios from 'axios';
    import moment from 'moment/src/moment';

    export default {
        components: {
            BSidebar,
            BForm,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BInputGroupAppend,
            BInputGroup,
            BFormFile,
            BFormInvalidFeedback,
            BButton,
            BFormDatepicker,
            vSelect,
            flatPickr,
            BCol,
            BRow,

            // Form Validation
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isEditFormSidebarActive',
            event: 'update:is-edit-form-sidebar-active',
        },
        props: {
            isEditFormSidebarActive: {
                type: Boolean,
                required: true,
            },
            formData:{
                type: Object,                
                required: false
            }
        },
        data() {                                   
            return {
                required,
                alphaNum,        
                deadline: null,   
            }
        }, 
        setup(props, { emit }) {
                        
            // Use toast
            const toast = useToast();
                        
            // const start_date = moment(props.formData.start_date).format("DD-MM-YYYY");  
                        
            const USER_APP_STORE_MODULE_NAME = 'app-form';

            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, formStoreModule);

            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });
            
            const optionsStates = ref([]);
            
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/state/select`)
            .then(response => {
                 optionsStates.value = response.data;
            });
            
            const optionsRevisers = reactive([]);            
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/revisers/select`)
            .then(response => {                
                response.data.map( item => {
					optionsRevisers.push({
						label : item.access.fullName, 
						value : item.id
					});
				});
            });

            const blankUserData = {               
                deadline: null,     
                auth: JSON.parse(localStorage.getItem('userData')).id
            };

            const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
            const resetuserData = () => {
                userData.value = JSON.parse(JSON.stringify(blankUserData));
            };
            
            const onSubmit = () => {
                                
                let reviserId = typeof  props.formData.reviser.access.fullName == "string" ? 
                                        props.formData.reviser.id :
                                        props.formData.reviser.access.fullName.value;
                                        
                let stateId =  typeof  props.formData.state.name === "string" ? 
                                       props.formData.state.id :                            
                                       props.formData.state.name.id;                
                        
                let start_date_new = moment(props.formData.start_date, "DD-MM-YYYY").toISOString();                
                let deadline_new = moment(props.formData.deadline, "DD-MM-YYYY HH:mm").toISOString();        
                
                let formData = {
                    reviser_id: reviserId,
                    state_id: stateId,
                    deadline: deadline_new,
                    start_date: start_date_new,
                    auth: JSON.parse(localStorage.getItem('userData')).id
                }
                
                store.dispatch('app-form/updateForm', { id: props.formData.id, formData })	
                .then( (response) => {        
                    emit('refetch-data');
                    
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    });

                    emit('update:is-edit-form-sidebar-active', false);
                })
                .catch( (err) => {
                    let message = err.response.data.message ? err.response.data.message : 'Error al actualizar el formulario.';
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });
                });
            };


            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetuserData);

            return {
                userData,
                onSubmit,

                refFormObserver,
                getValidationState,
                resetForm,
                
                optionsStates,
                optionsRevisers,
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">

    #edit-form-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }

    #preview {
        text-align: center;
        margin-bottom: 1rem;
    }

</style>
